export function removeEmptyValues(obj) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] == "") {
      delete obj[key];
    } else if (
      typeof obj[key] === "object" &&
      Object.keys(obj[key]).length === 0
    ) {
      delete obj[key];
    }
  }
  return obj;
}

<template>
  <div>
    <div class="page-content border-bottom">
      <!-- 顶部搜索框 -->
      <div>
        <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
          <!-- 日期选择框 -->
          <el-form-item label="时间">
            <el-date-picker
              @change="handleChangeDate"
              v-model="datePickerVal"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="城市">
            <el-input
              style="width: 170px"
              v-model="searchInfo.city"
              placeholder="请输入城市"
              class="form-item-width"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 右侧查询 -->
          <el-form-item class="right-btns">
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="page-content margin-top">
      <!-- 表格组件 -->
      <el-table
        :data="invitationTableData"
        stripe
        :summary-method="getSummaries"
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <el-table-column label="序号" width="70" align="left">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="tab in invitationTableTitle"
          :key="tab.code"
          :label="tab.name"
          show-overflow-tooltip
          :width="tab.width"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[tab.code] }} </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <!-- <div>
        <Pagination
          :total="dataTotal"
          :page="searchInfo.page"
          :limit="searchInfo.limit"
          @pagination="onChangePages"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import {
  getInviteKanban,
  getTimeInviteKanban,
  getTimeeInviteKanban,
} from "../../../api/common";
import { removeEmptyValues } from "../../../utils/removeEmptyValues";

export default {
  components: {
    // Pagination,
  },
  data() {
    return {
      // 日期选择框绑定值
      datePickerVal: [
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1",
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-30",
      ],
      // 查询数据
      searchInfo: {
        startTime: "",
        endTime: "",
        city: "",
        // page: 1,
        // size: 10,
      },
      // 表格数据
      invitationTableData: [], //邀约
      businessesTableData: [], //商户
      fundTableData: [], //资金
      // 表格标题数组
      invitationTableTitle: [
        //邀约
        { name: "所在城市", code: "city", width: "" },
        { name: "线索量", code: "gross", width: "" },
        // { name: "重复线索", code: "dataCount", width: "" },
        { name: "下发工单数", code: "clean", width: "" },
        { name: "申诉成功", code: "appeal", width: "" },
        { name: "工单转化率", code: "conversionRate", width: "" },
      ],
      businessesTableTitle: [
        //商户
        { name: "姓名", code: "address", width: "" },
        { name: "所在城市", code: "dataCount", width: "" },
        { name: "工单上限量", code: "address", width: "" },
        { name: "已下发工单数", code: "dataCount", width: "" },
        { name: "申诉成功", code: "address", width: "" },
        { name: "剩余工单数", code: "dataCount", width: "" },
        { name: "已成交", code: "address", width: "" },
        { name: "成交转化率", code: "dataCount", width: "" },
      ],
      fundTableTitle: [
        //资金
        { name: "所在城市", code: "address", width: "" },
        { name: "套餐工单数", code: "dataCount", width: "" },
        { name: "资金收入", code: "dataCount", width: "" },
      ],
      // 数据总数
      dataTotal: 100,
    };
  },
  mounted() {
    // console.log(this.datePickerVal);
    this.searchInfo.startTime = this.datePickerVal[0];
    this.searchInfo.endTime = this.datePickerVal[1];
    this.firstList(this.searchInfo);
    // this.onGetDataList(this.searchInfo);
  },
  methods: {
    // 修改日期
    handleChangeDate(date) {
      this.searchInfo.startTime = date[0];
      this.searchInfo.endTime = date[1];
      console.log(this.searchInfo);
    },
    // 查询
    onSearch() {
      // this.searchInfo.page = 1
      this.onGetDataList(this.searchInfo);
    },
    // 重置
    onReset() {
      this.searchInfo.startTime = "";
      this.searchInfo.endTime = "";
      this.searchInfo.city = "";
      this.datePickerVal = "";
      this.onSearch();
    },
    firstList(searchInfo) {
      const result = removeEmptyValues(searchInfo);
      console.log(result);
      getTimeInviteKanban(result).then((res) => {
        this.invitationTableData = res.data.queryResult.list;
        this.invitationTableData.forEach((item) => {
          const num = ((item.clean / item.gross) * 100).toFixed(2) + "%";
          item.conversionRate = num;
        });
      });
    },
    // 核心查询数据方法
    onGetDataList(searchInfo) {
      const result = removeEmptyValues(searchInfo);
      console.log(result);
      // 查询数据方法
      if (result.city) {
        getInviteKanban(result).then((res) => {
          console.log(res.data.invite);
          this.invitationTableData = [res.data.invite];
          this.invitationTableData.forEach((item) => {
            item.conversionRate =
              ((item.clean / item.gross) * 100).toFixed(2) + "%";
          });
          console.log(this.invitationTableData);
        });
      } else {
        getTimeeInviteKanban(result.startTime, result.endTime).then((res) => {
          this.invitationTableData = res.data.queryResult.list;
          this.invitationTableData.forEach((item) => {
            item.conversionRate =
              ((item.clean / item.gross) * 100).toFixed(2) + "%";
          });
        });
      }
    },
    //合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总价";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += " ";
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
  },
};
</script>

<style lang="less" scoped>
.form-item-width {
  width: 250px;
}
.right-btns {
  position: absolute;
  right: 20px !important;
}
.el-form-item {
  margin-bottom: 4px !important;
}
.border-bottom {
  border-bottom: 1px solid #e2e2e4;
}
.margin-top {
  margin-top: -15px;
}
</style>

import http from "../utils/http";

//邀约管理列表
export function getInvitationList(limitPage) {
  return http({
    url: `/task/list/${limitPage.page}/${limitPage.limit}`,
    method: "get",
  });
}

//邀约详情
export function getInvitationDetail(id) {
  return http({
    url: "/task/manage/" + id,
    method: "get",
  });
}

//下发工单
export function getissuedTickets(id) {
  return http({
    url: `/task/${id}/a`,
    headers: { token: localStorage.getItem("token") },
    method: "get",
  });
}

//下次跟进
export function getFollowUp(id, val) {
  return http({
    url: `/task/p/${id}/${val}/`,
    headers: { token: localStorage.getItem("token") },
    method: "put",
  });
}

//暂时不卖
export function getTemporarily(id) {
  return http({
    url: "/task/private/" + id,
    headers: { token: localStorage.getItem("token") },
    method: "put",
  });
}

//新增跟进记录
export function addRecordKeeping(sid, serviceRecord, kid) {
  return http({
    headers: { token: localStorage.getItem("token") },
    url: `/task/add/${sid}/${serviceRecord}/${kid}`,
    method: "post",
  });
}

//申诉处理
export function getAuditResults(cid, sid, val) {
  return http({
    headers: { token: localStorage.getItem("token") },
    url: `/task/${cid}/${sid}/${val}`,
    method: "get",
  });
}

//工单分配
export function getTicketsList() {
  return http({
    headers: { token: localStorage.getItem("token") },
    url: "/task/work",
    method: "get",
  });
}

//未分配
export function getNotAssignedList(id) {
  return http({
    url: "/task/user/" + id,
    method: "get",
  });
}

//分配操作
export function getAssignedOperate(cid, sid) {
  return http({
    headers: { token: localStorage.getItem("token") },
    url: `/task/add/${sid}/${cid}`,
    method: "get",
  });
}

//分配完成
export function getAssignmentList() {
  return http({
    url: "/task/accomplish",
    method: "get",
  });
}

//申诉管理
export function getComplaintList() {
  return http({
    url: "/task/audit",
    method: "get",
  });
}

//审核
export function getAuditResult() {
  return http({
    url: "/task/audit",
    method: "get",
  });
}

//私库管理
export function getPrivateList() {
  return http({
    url: "/task/private",
    method: "get",
  });
}

//商户管理
export function getBusinessesList() {
  return http({
    url: "/task/commercia/list",
    method: "get",
  });
}

//商户详情
export function getgetDetailList(cid) {
  return http({
    url: `/task/find/Commercial/${cid}?cid=` + cid,
    method: "get",
  });
}

//首次进入邀约
export function getTimeInviteKanban(data) {
  return http({
    url: `/task/inv/time/${data.startTime}/${data.endTime}`,
    method: "get",
  });
}

//邀约看板
export function getInviteKanban(data) {
  return http({
    url: `/task/inv/${data.startTime}/${data.endTime}/${data.city}`,
    method: "get",
  });
}

//邀约看板根据时间查询
export function getTimeeInviteKanban(begin, finish) {
  return http({
    url: `/task/inv/time/${begin}/${finish}`,
    method: "get",
  });
}

//商户看板
export function getMerchantKanban(city) {
  return http({
    url: `/task/CommercialBoard/${city ? "{city}?city=" + city : "{city}"}`,
    method: "get",
  });
}

//资金看板
export function getFundingKanban(begin, finish) {
  return http({
    url: `/task/csm/${begin}/${finish}`,
    method: "get",
  });
}

//线索购买记录
export function getPurchaseHistory(cid) {
  return http({
    url: `/task/find/comSetmeal/${cid}`,
    method: "get",
  });
}

//跟进记录
export function getFollowupRecords(params) {
  return http({
    url: `/communicate/find/remark/{sid}`,
    method: "post",
    params,
  });
}

//通话记录
export function getCallLogs(params) {
  return http({
    url: `/communicate/find/{sid}`,
    method: "post",
    params,
  });
}

//查询邀约用户
export function SearchInvitationList(params) {
  return http({
    url: "/task/Userinfo",
    method: "get",
    params,
  });
}

//查询商户
export function SearchBusinessesList(params) {
  return http({
    url: "/task/commercial",
    method: "post",
    params,
  });
}

//修改工单上限
export function updataUpperLimit(id, distribute, amend) {
  return http({
    headers: { token: localStorage.getItem("token") },
    url: `/task/${id}/${distribute}/${amend}`,
    method: "get",
  });
}
